<template>
    <div>
        <div>
            <v-col cols="12" sm="4">
                <h4>Actualizar Consulta de Paciente</h4>
            </v-col>
        </div>

        <template>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled class="styled-input" v-model="currentPaciente.nombre" type="text"
                            label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled v-model="currentPaciente.apellido" type="text"
                            label="Apellido"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled v-model="currentPaciente.documento" counter="8" type="number"
                            label="Documento"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field disabled v-model="currentPaciente.telefono" type="number"
                            label="Telefono"></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <template>
                        <v-container fluid>
                            <v-textarea name="inputConsulta" variant="filled" label="Datos de la Consulta:" auto-grow
                                v-model="currentConsulta.notasConsulta"></v-textarea>
                        </v-container>
                        <v-checkbox class="align-center justify-center" label="Requiere Seguimiento"
                            v-model="currentConsulta.seguimientoConsulta"></v-checkbox>
                    </template>

                </v-row>

            </v-container>
        </template>


        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="4">
                <v-menu v-model="fechaDTP" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="currentConsulta.fechaConsulta" label="Fecha de Consulta" v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="fechaDTP = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>

        <div class="text-center d-flex mt-3 pb-4">
            <v-btn color="primary" @click="updateConsulta(); snackbar = true, updateConsulta()">
                Actualizar
            </v-btn>
            <v-btn class="ml-1" width="auto" color="secondary" @click="goToList">
                Listado de Consultas
            </v-btn>
            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        </div>

        <v-snackbar v-model="snackbar" :multi-line="multiLine">
            Actualizado correctamente a las {{ showEditedTime() }}
            <template v-slot:action="{ attrs }">
                <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import PacienteDataService from "../services/PacienteDataService";
import ConsultaDataService from "../services/ConsultaDataService";
import ConfirmDialogue from './ConfirmDialogue.vue';


export default {
    name: "consulta",
    components: {
        ConfirmDialogue
    },
    data() {

        return {
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            fechaDTP: false,
            multiLine: true,
            snackbar: false,
            panel: [],
            items: 3,
            alert: false,
            currentPaciente: {
                id: null,
                nombre: "",
                apellido: "",
                telefono: "",
                documento: ""
            },
            currentConsulta: {
                idConsulta: null,
                notasConsulta: null,
                fechaConsulta: null,
                seguimientoConsulta: null
            }
        };
    },
    methods: {
        async goToList() {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Volver al listado de consultas?',
                message: 'Los cambios no guardados se perderan.',
                okButton: 'Volver',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.verConsultas(this.currentPaciente.id)
            } else {
                // Doing nothing now, can throw an alert() too
            }
        },
        showEditedTime() {
            var editedTime = new Date();
            editedTime = editedTime.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            return editedTime
        },


        getConsulta(id) {
            ConsultaDataService.get(id)
                .then((response) => {
                    this.currentConsulta = response.data;
                    this.getPaciente(this.currentConsulta.idPacienteConsulta);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        getPaciente(id) {
            PacienteDataService.get(id)
                .then(response => {
                    this.currentPaciente = response.data;
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },


        verConsultas(id) {
            this.$router.push({
                name: "consultaList",
                params: {
                    id: id
                }
            });
        },

        updateConsulta() {
            console.log(this.currentConsulta);

            var data = {
                idPacienteConsulta: this.currentConsulta.idPacienteConsulta,
                notasConsulta: this.currentConsulta.notasConsulta,
                fechaConsulta: this.currentConsulta.fechaConsulta,
                seguimientoConsulta: this.currentConsulta.seguimientoConsulta,
                id: this.currentConsulta.id
            };

            ConsultaDataService.update(this.currentConsulta.id, data)
                .then(response => {
                    console.log("updateMutualista() Response data: " + response.data);

                })
                .catch(e => {
                    console.log(e);
                });
                //this.getConsulta(this.currentConsulta.id)
                //this.getConsulta(this.currentConsulta.idPacienteConsulta)
        },

    },
    mounted() {
        this.getConsulta(this.$route.params.id)
    }
};
</script>

<style>
/* Hide up and down buttons on numeric type fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
